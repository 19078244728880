<template>
  <div>
    <el-dialog title="添加学生"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="40px">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="姓名">
              <el-input clearable
                        style="width:100%"
                        placeholder="关键字"
                        v-model="form.truename"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="届">
              <el-select v-model="form.graduated_id"
                         style="width:100%"
                         disabled
                         placeholder="请选择">
                <el-option v-for="item in jieList"
                           :key="item.year"
                           :label="item.year_desc"
                           :value="item.year">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="班级">
              <el-select v-model="form.class_id"
                         style="width:100%"
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in classList"
                           :key="item.class_id"
                           :label="item.class_name"
                           :value="item.class_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="12">
          <el-button type="primary"
                     @click="initData">查询</el-button>
        </el-col>
      </el-row>

      <el-table border
                :stripe="true"
                ref="multipleTable"
                highlight-current-row
                :max-height="$store.state.tableHeight / 2"
                :data="tableData"
                @selection-change="handleSelectionChange"
                style="width: 100%">
        <el-table-column type="selection"
                         :selectable="checkSelectable"
                         width="55" />
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />
      </el-table>
      <p style="font-size:18px;font-weight:bold;line-height:50px"><strong>已选择学生：</strong></p>
      <el-table border
                :stripe="true"
                highlight-current-row
                :max-height="$store.state.tableHeight / 2"
                :data="arr"
                style="width: 100%">
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />
        <el-table-column align="center"
                         label="操作">
          <template slot-scope="scope">
            <div class="text_btn_danger"
                 @click="delStu(scope.$index)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="btn">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      width: '800px',
      special_class_id: '',
      dialogVisible: false,
      classList: [],
      tableData: [],
      arr: [],
      jieList: []
    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  methods: {
    getJieList (id) {
      this.$http({
        url: '/api/v1/public/class_graduate_year',
        method: 'GET',
        params: {
          class_subject_id: id
        }
      }).then(res => {
        this.jieList = res.data
        this.form.graduated_id = res.data[0].year
        this.getClass()
      })
    },
    checkSelectable (row) {
      if (this.arr.findIndex(item => row.id == item.id) == -1) {
        return true
      }
      return false
    },
    delStu (index) {
      let row = this.arr.splice(index, 1)[0]
      console.log(row)
      if (this.tableData.findIndex(item => row.id == item.id) !== -1 && row.password) {
        setTimeout(() => {
          this.$refs.multipleTable.toggleRowSelection(row);
        }, 300);
      }
    },
    handleClose () {
      this.form = {
        class_id: '',
      }
      this.arr = []
      this.tableData = []
      this.dialogVisible = false
    },
    handleSelectionChange (value) {
      this.arr = [...this.arr, ...value]
      this.deWeight()
    },
    deWeight () {
      let arr = this.arr
      for (var index = 0; index < arr.length; index++) {
        for (var idx = index + 1; idx < arr.length; idx++) {
          if (arr[idx] && arr[index] && arr[idx].id == arr[index].id) {
            arr.splice(index, 1)
            index -= 1
          }
        }
      }
    },
    getClass () {
      this.$http({
        url: '/api/v1/school/get_graduated_class',
        method: 'GET',
        params: {
          graduated_id: this.form.graduated_id
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    getList (id) {
      this.getJieList(id)
    },
    initData () {
      this.tableData = []
      this.$http({
        url: '/api/v1/special/search_student',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data
      })
    },
    save () {
      let students = this.arr.map(item => item.id)
      this.$http({
        url: '/api/v1/special/add_student',
        method: 'post',
        data: {
          special_class_subject_id: this.special_class_id,
          students: students.join(',')
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.$parent.search()
        this.handleClose()
      }).catch(e => {
        this.$message.warning(e.data.msg)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.btn {
  text-align: center;
  margin-top: 40px;
}
</style>
